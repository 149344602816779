import { authorizedJSONRequest } from "../../auth/auth-api";
import { getMenuElements } from "./data";

export const fetchAccessToMenuElements = async (): Promise<
  Record<string, boolean>
> => {
  const el: string[] = getMenuElements().reduce<string[]>((p, c) => {
    const routes = c.elements.map((element) => element.route);
    return p.concat(routes);
  }, []);

  return (
    await authorizedJSONRequest<Record<string, boolean>>("/admin/menu", {
      routes: el,
    })
  ).data;
};
