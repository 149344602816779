import { getTransport } from '../configureService';
import { MessageType } from "@protobuf-ts/runtime";
import { ConfigKey } from "@as-pl/env/src/getEnv";
import {ComplainsServiceClient as ErpBackendComplainsService} from './erp-backend/grpcComplains.client';
import {ProducersServiceClient as ErpBackendProducersService} from './erp-backend/grpcProducers.client';
import {ProductEditServiceClient as ErpBackendProductEditService} from './erp-backend/grpcProduct_tecodc.client';
import {ProductImageExporterServiceClient as ErpBackendProductImageExporterService} from './erp-backend/grpcProductimageexporter.client';
import {ProfilesServiceClient as ErpBackendProfilesService} from './erp-backend/grpcProfiles_service.client';
import {TecdocServiceClient as ErpBackendTecdocService} from './erp-backend/grpcTecdoc_service.client';
import {TodoServiceClient as ErpBackendTodoService} from './erp-backend/grpcTodo_service.client';
import {QueueServiceClient as QueueManagerQueueService} from './queue-manager/grpcServices.client';
import {AssetsServiceClient as QueueManagerAssetsService} from './queue-manager/grpcServices.client';
import {QueueServiceTaskChainClient as QueueManagerQueueServiceTaskChain} from './queue-manager/grpcServices.client';
import {TrackClient as TaskMonitorTrack} from './task-monitor/grpcMonitor.client';


type ExtractGeneric<T> = T extends MessageType<infer U> ? U : never;


import  * as ErpBackendComplainsServiceTypesImport from './erp-backend/grpcComplains';
type ListColumnValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListColumnValue>; 
type FilterErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Filter>; 
type ListOrderValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListOrderValue>; 
type OrderErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Order>; 
type MapStringStringValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.MapStringStringValue>; 
type DataQueryErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.DataQuery>; 
type MonitorMessageErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.MonitorMessage>; 
type ColumnErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.Column>; 
type ListFilterValueErpBackendComplainsServiceType = ExtractGeneric<typeof ErpBackendComplainsServiceTypesImport.ListFilterValue>; 
import  * as ErpBackendProducersServiceTypesImport from './erp-backend/grpcProducers';
type ListSortModelValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListSortModelValue>; 
type FilterModelErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterModel>; 
type AgGridQueryDataErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.AgGridQueryData>; 
type ListStringValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListStringValue>; 
type ListProducerValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListProducerValue>; 
type SortModelErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.SortModel>; 
type NullInt32ErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.NullInt32>; 
type ProducersGridResultErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ProducersGridResult>; 
type FilterConditionErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterCondition>; 
type ListFilterModelValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListFilterModelValue>; 
type GetProducersRowErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.GetProducersRow>; 
type ListFilterConditionClValueErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.ListFilterConditionClValue>; 
type FilterConditionClErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.FilterConditionCl>; 
type ProducerErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.Producer>; 
type AddCNInputErpBackendProducersServiceType = ExtractGeneric<typeof ErpBackendProducersServiceTypesImport.AddCNInput>; 
import  * as ErpBackendProductEditServiceTypesImport from './erp-backend/grpcProduct_tecodc';
type AgGridQueryDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.AgGridQueryData>; 
type GetEnginesRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesRow>; 
type ListSearchDataValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSearchDataValue>; 
type SearchForApplicationRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchForApplicationRow>; 
type InsertManyAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.InsertManyAppsInput>; 
type ListFilterConditionClValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListFilterConditionClValue>; 
type FilterConditionErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterCondition>; 
type FilterModelErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterModel>; 
type EngineResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.EngineResult>; 
type GetLinkedCrossListRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetLinkedCrossListRow>; 
type SearchDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchData>; 
type GetEnginesInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetEnginesInput>; 
type GetLinkedCrossInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetLinkedCrossInput>; 
type ListIN_QueryData_OrderValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_OrderValue>; 
type IN_QueryData_Filters_ValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Filters_Value>; 
type NullStringErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.NullString>; 
type ListInsertManyAppsInputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListInsertManyAppsInputValue>; 
type LinkAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.LinkAppsInput>; 
type ListGetLinkedCrossListRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetLinkedCrossListRowValue>; 
type SearchDataInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchDataInput>; 
type ListIN_QueryData_Filters_ValueValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_Filters_ValueValue>; 
type ListIN_QueryData_FiltersValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIN_QueryData_FiltersValue>; 
type IN_QueryData_FiltersErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Filters>; 
type ImportAppsInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ImportAppsInput>; 
type SortModelErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SortModel>; 
type ListStringValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListStringValue>; 
type ApplicationRowErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationRow>; 
type ListManySearchResultValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListManySearchResultValue>; 
type ApplicationHistoryOutputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationHistoryOutput>; 
type FilterConditionClErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.FilterConditionCl>; 
type ListIntValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListIntValue>; 
type ListApplicationRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListApplicationRowValue>; 
type QueryDataErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.QueryData>; 
type ApplicationHistoryInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationHistoryInput>; 
type ListApplicationHistoryOutputValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListApplicationHistoryOutputValue>; 
type ListSearchForApplicationRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSearchForApplicationRowValue>; 
type ManySearchResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ManySearchResult>; 
type ListSortModelValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListSortModelValue>; 
type ListFilterModelValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListFilterModelValue>; 
type ListGetEnginesRowValueErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ListGetEnginesRowValue>; 
type GetAppGlobalInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetAppGlobalInput>; 
type GetAppListInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.GetAppListInput>; 
type SearchForManyApplicationInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.SearchForManyApplicationInput>; 
type MergeInputErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.MergeInput>; 
type ApplicationResultErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.ApplicationResult>; 
type IN_QueryData_OrderErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.IN_QueryData_Order>; 
type NullInt32ErpBackendProductEditServiceType = ExtractGeneric<typeof ErpBackendProductEditServiceTypesImport.NullInt32>; 
import  * as ErpBackendProductImageExporterServiceTypesImport from './erp-backend/grpcProductimageexporter';
type InputErpBackendProductImageExporterServiceType = ExtractGeneric<typeof ErpBackendProductImageExporterServiceTypesImport.Input>; 
import  * as ErpBackendProfilesServiceTypesImport from './erp-backend/grpcProfiles_service';
type ListStringValueErpBackendProfilesServiceType = ExtractGeneric<typeof ErpBackendProfilesServiceTypesImport.ListStringValue>; 
import  * as ErpBackendTecdocServiceTypesImport from './erp-backend/grpcTecdoc_service';
type AuthDataErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.AuthData>; 
type MetaDataErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.MetaData>; 
type BaseErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.Base>; 
type TecDocImporterMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.TecDocImporterMessage>; 
type MonitorMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.MonitorMessage>; 
type TecDocApplicationImporterMessageErpBackendTecdocServiceType = ExtractGeneric<typeof ErpBackendTecdocServiceTypesImport.TecDocApplicationImporterMessage>; 
import  * as ErpBackendTodoServiceTypesImport from './erp-backend/grpcTodo_service';
type NullStringErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.NullString>; 
type ListGetTodoTasksRowValueErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.ListGetTodoTasksRowValue>; 
type GetTodoTasksRowErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.GetTodoTasksRow>; 
type MapStringStringValueErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.MapStringStringValue>; 
type ListGetListsRowValueErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.ListGetListsRowValue>; 
type GetListsRowErpBackendTodoServiceType = ExtractGeneric<typeof ErpBackendTodoServiceTypesImport.GetListsRow>; 

import  * as QueueManagerQueueServiceTypesImport from './queue-manager/grpcServices';
type GetQueueMessInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetQueueMessInput>; 
type ListStringValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListStringValue>; 
type GetTasksListInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetTasksListInput>; 
type ListPointerTaskInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListPointerTaskInfoValue>; 
type ListPointerQueueInfoValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueInfo>; 
type ListByteValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListByteValue>; 
type CancelTaskInputQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.CancelTaskInput>; 
type TaskStreamMessageQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskStreamMessage>; 
type DirInfoStructQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.DirInfoStruct>; 
type DirInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.DirInfo>; 
type TaskInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.TaskInfo>; 
type ListGetQueueMessRowValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.GetQueueMessRow>; 
type QueueServerInfoQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.QueueServerInfo>; 
type ListDirInfoStructValueQueueManagerQueueServiceType = ExtractGeneric<typeof QueueManagerQueueServiceTypesImport.ListDirInfoStructValue>; 
import  * as QueueManagerAssetsServiceTypesImport from './queue-manager/grpcServices';
type GetQueueMessInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetQueueMessInput>; 
type ListStringValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListStringValue>; 
type GetTasksListInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetTasksListInput>; 
type ListPointerTaskInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListPointerTaskInfoValue>; 
type ListPointerQueueInfoValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueInfo>; 
type ListByteValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListByteValue>; 
type CancelTaskInputQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.CancelTaskInput>; 
type TaskStreamMessageQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskStreamMessage>; 
type DirInfoStructQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.DirInfoStruct>; 
type DirInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.DirInfo>; 
type TaskInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.TaskInfo>; 
type ListGetQueueMessRowValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.GetQueueMessRow>; 
type QueueServerInfoQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.QueueServerInfo>; 
type ListDirInfoStructValueQueueManagerAssetsServiceType = ExtractGeneric<typeof QueueManagerAssetsServiceTypesImport.ListDirInfoStructValue>; 
import  * as QueueManagerQueueServiceTaskChainTypesImport from './queue-manager/grpcServices';
type GetQueueMessInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetQueueMessInput>; 
type ListStringValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListStringValue>; 
type GetTasksListInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetTasksListInput>; 
type ListPointerTaskInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListPointerTaskInfoValue>; 
type ListPointerQueueInfoValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListPointerQueueInfoValue>; 
type QueueInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueInfo>; 
type ListByteValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListByteValue>; 
type CancelTaskInputQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.CancelTaskInput>; 
type TaskStreamMessageQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskStreamMessage>; 
type DirInfoStructQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.DirInfoStruct>; 
type DirInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.DirInfo>; 
type TaskInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.TaskInfo>; 
type ListGetQueueMessRowValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListGetQueueMessRowValue>; 
type GetQueueMessRowQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.GetQueueMessRow>; 
type QueueServerInfoQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.QueueServerInfo>; 
type ListDirInfoStructValueQueueManagerQueueServiceTaskChainType = ExtractGeneric<typeof QueueManagerQueueServiceTaskChainTypesImport.ListDirInfoStructValue>; 

import  * as TaskMonitorTrackTypesImport from './task-monitor/grpcMonitor';
type MonitorInputMessageTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.MonitorInputMessage>; 
type TaskMonitorQueryTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.TaskMonitorQuery>; 
type MonitorMessageTaskMonitorTrackType = ExtractGeneric<typeof TaskMonitorTrackTypesImport.MonitorMessage>; 


const transports = {
  ErpBackend: getTransport( ConfigKey.NEXT_PUBLIC_BACKEND ),
  QueueManager: getTransport( ConfigKey.NEXT_PUBLIC_QUEUE_MANAGER ),
  TaskMonitor: getTransport( ConfigKey.NEXT_PUBLIC_QUEUE_MANAGER ),
  }


export class GrpcManager {
static  ErpBackend= {
  getComplainsService(){
 return new ErpBackendComplainsService(transports.ErpBackend)  },
  getProducersService(){
 return new ErpBackendProducersService(transports.ErpBackend)  },
  getProductEditService(){
 return new ErpBackendProductEditService(transports.ErpBackend)  },
  getProductImageExporterService(){
 return new ErpBackendProductImageExporterService(transports.ErpBackend)  },
  getProfilesService(){
 return new ErpBackendProfilesService(transports.ErpBackend)  },
  getTecdocService(){
 return new ErpBackendTecdocService(transports.ErpBackend)  },
  getTodoService(){
 return new ErpBackendTodoService(transports.ErpBackend)  },
}
static  QueueManager= {
  getQueueService(){
 return new QueueManagerQueueService(transports.QueueManager)  },
  getAssetsService(){
 return new QueueManagerAssetsService(transports.QueueManager)  },
  getQueueServiceTaskChain(){
 return new QueueManagerQueueServiceTaskChain(transports.QueueManager)  },
}
static  TaskMonitor= {
  getTrack(){
 return new TaskMonitorTrack(transports.TaskMonitor)  },
}
}


export namespace Types{
	export namespace ErpBackend{
		export namespace ComplainsService{
			export type  ListColumnValue=ListColumnValueErpBackendComplainsServiceType;
 			export type  Filter=FilterErpBackendComplainsServiceType;
 			export type  ListOrderValue=ListOrderValueErpBackendComplainsServiceType;
 			export type  Order=OrderErpBackendComplainsServiceType;
 			export type  MapStringStringValue=MapStringStringValueErpBackendComplainsServiceType;
 			export type  DataQuery=DataQueryErpBackendComplainsServiceType;
 			export type  MonitorMessage=MonitorMessageErpBackendComplainsServiceType;
 			export type  Column=ColumnErpBackendComplainsServiceType;
 			export type  ListFilterValue=ListFilterValueErpBackendComplainsServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProducersService{
			export type  ListSortModelValue=ListSortModelValueErpBackendProducersServiceType;
 			export type  FilterModel=FilterModelErpBackendProducersServiceType;
 			export type  AgGridQueryData=AgGridQueryDataErpBackendProducersServiceType;
 			export type  ListStringValue=ListStringValueErpBackendProducersServiceType;
 			export type  ListProducerValue=ListProducerValueErpBackendProducersServiceType;
 			export type  SortModel=SortModelErpBackendProducersServiceType;
 			export type  NullInt32=NullInt32ErpBackendProducersServiceType;
 			export type  ProducersGridResult=ProducersGridResultErpBackendProducersServiceType;
 			export type  FilterCondition=FilterConditionErpBackendProducersServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendProducersServiceType;
 			export type  GetProducersRow=GetProducersRowErpBackendProducersServiceType;
 			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendProducersServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendProducersServiceType;
 			export type  Producer=ProducerErpBackendProducersServiceType;
 			export type  AddCNInput=AddCNInputErpBackendProducersServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProductEditService{
			export type  AgGridQueryData=AgGridQueryDataErpBackendProductEditServiceType;
 			export type  GetEnginesRow=GetEnginesRowErpBackendProductEditServiceType;
 			export type  ListSearchDataValue=ListSearchDataValueErpBackendProductEditServiceType;
 			export type  SearchForApplicationRow=SearchForApplicationRowErpBackendProductEditServiceType;
 			export type  InsertManyAppsInput=InsertManyAppsInputErpBackendProductEditServiceType;
 			export type  ListFilterConditionClValue=ListFilterConditionClValueErpBackendProductEditServiceType;
 			export type  FilterCondition=FilterConditionErpBackendProductEditServiceType;
 			export type  FilterModel=FilterModelErpBackendProductEditServiceType;
 			export type  EngineResult=EngineResultErpBackendProductEditServiceType;
 			export type  GetLinkedCrossListRow=GetLinkedCrossListRowErpBackendProductEditServiceType;
 			export type  SearchData=SearchDataErpBackendProductEditServiceType;
 			export type  GetEnginesInput=GetEnginesInputErpBackendProductEditServiceType;
 			export type  GetLinkedCrossInput=GetLinkedCrossInputErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_OrderValue=ListIN_QueryData_OrderValueErpBackendProductEditServiceType;
 			export type  IN_QueryData_Filters_Value=IN_QueryData_Filters_ValueErpBackendProductEditServiceType;
 			export type  NullString=NullStringErpBackendProductEditServiceType;
 			export type  ListInsertManyAppsInputValue=ListInsertManyAppsInputValueErpBackendProductEditServiceType;
 			export type  LinkAppsInput=LinkAppsInputErpBackendProductEditServiceType;
 			export type  ListGetLinkedCrossListRowValue=ListGetLinkedCrossListRowValueErpBackendProductEditServiceType;
 			export type  SearchDataInput=SearchDataInputErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_Filters_ValueValue=ListIN_QueryData_Filters_ValueValueErpBackendProductEditServiceType;
 			export type  ListIN_QueryData_FiltersValue=ListIN_QueryData_FiltersValueErpBackendProductEditServiceType;
 			export type  IN_QueryData_Filters=IN_QueryData_FiltersErpBackendProductEditServiceType;
 			export type  ImportAppsInput=ImportAppsInputErpBackendProductEditServiceType;
 			export type  SortModel=SortModelErpBackendProductEditServiceType;
 			export type  ListStringValue=ListStringValueErpBackendProductEditServiceType;
 			export type  ApplicationRow=ApplicationRowErpBackendProductEditServiceType;
 			export type  ListManySearchResultValue=ListManySearchResultValueErpBackendProductEditServiceType;
 			export type  ApplicationHistoryOutput=ApplicationHistoryOutputErpBackendProductEditServiceType;
 			export type  FilterConditionCl=FilterConditionClErpBackendProductEditServiceType;
 			export type  ListIntValue=ListIntValueErpBackendProductEditServiceType;
 			export type  ListApplicationRowValue=ListApplicationRowValueErpBackendProductEditServiceType;
 			export type  QueryData=QueryDataErpBackendProductEditServiceType;
 			export type  ApplicationHistoryInput=ApplicationHistoryInputErpBackendProductEditServiceType;
 			export type  ListApplicationHistoryOutputValue=ListApplicationHistoryOutputValueErpBackendProductEditServiceType;
 			export type  ListSearchForApplicationRowValue=ListSearchForApplicationRowValueErpBackendProductEditServiceType;
 			export type  ManySearchResult=ManySearchResultErpBackendProductEditServiceType;
 			export type  ListSortModelValue=ListSortModelValueErpBackendProductEditServiceType;
 			export type  ListFilterModelValue=ListFilterModelValueErpBackendProductEditServiceType;
 			export type  ListGetEnginesRowValue=ListGetEnginesRowValueErpBackendProductEditServiceType;
 			export type  GetAppGlobalInput=GetAppGlobalInputErpBackendProductEditServiceType;
 			export type  GetAppListInput=GetAppListInputErpBackendProductEditServiceType;
 			export type  SearchForManyApplicationInput=SearchForManyApplicationInputErpBackendProductEditServiceType;
 			export type  MergeInput=MergeInputErpBackendProductEditServiceType;
 			export type  ApplicationResult=ApplicationResultErpBackendProductEditServiceType;
 			export type  IN_QueryData_Order=IN_QueryData_OrderErpBackendProductEditServiceType;
 			export type  NullInt32=NullInt32ErpBackendProductEditServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProductImageExporterService{
			export type  Input=InputErpBackendProductImageExporterServiceType;
 }
}
	export namespace ErpBackend{
		export namespace ProfilesService{
			export type  ListStringValue=ListStringValueErpBackendProfilesServiceType;
 }
}
	export namespace ErpBackend{
		export namespace TecdocService{
			export type  AuthData=AuthDataErpBackendTecdocServiceType;
 			export type  MetaData=MetaDataErpBackendTecdocServiceType;
 			export type  Base=BaseErpBackendTecdocServiceType;
 			export type  TecDocImporterMessage=TecDocImporterMessageErpBackendTecdocServiceType;
 			export type  MonitorMessage=MonitorMessageErpBackendTecdocServiceType;
 			export type  TecDocApplicationImporterMessage=TecDocApplicationImporterMessageErpBackendTecdocServiceType;
 }
}
	export namespace ErpBackend{
		export namespace TodoService{
			export type  NullString=NullStringErpBackendTodoServiceType;
 			export type  ListGetTodoTasksRowValue=ListGetTodoTasksRowValueErpBackendTodoServiceType;
 			export type  GetTodoTasksRow=GetTodoTasksRowErpBackendTodoServiceType;
 			export type  MapStringStringValue=MapStringStringValueErpBackendTodoServiceType;
 			export type  ListGetListsRowValue=ListGetListsRowValueErpBackendTodoServiceType;
 			export type  GetListsRow=GetListsRowErpBackendTodoServiceType;
 }
}
	export namespace QueueManager{
		export namespace QueueService{
			export type  GetQueueMessInput=GetQueueMessInputQueueManagerQueueServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerQueueServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerQueueServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerQueueServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerQueueServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerQueueServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerQueueServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerQueueServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerQueueServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerQueueServiceType;
 			export type  DirInfo=DirInfoQueueManagerQueueServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerQueueServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerQueueServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerQueueServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerQueueServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerQueueServiceType;
 }
		export namespace AssetsService{
			export type  GetQueueMessInput=GetQueueMessInputQueueManagerAssetsServiceType;
 			export type  ListStringValue=ListStringValueQueueManagerAssetsServiceType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerAssetsServiceType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerAssetsServiceType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerAssetsServiceType;
 			export type  QueueInfo=QueueInfoQueueManagerAssetsServiceType;
 			export type  ListByteValue=ListByteValueQueueManagerAssetsServiceType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerAssetsServiceType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerAssetsServiceType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerAssetsServiceType;
 			export type  DirInfo=DirInfoQueueManagerAssetsServiceType;
 			export type  TaskInfo=TaskInfoQueueManagerAssetsServiceType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerAssetsServiceType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerAssetsServiceType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerAssetsServiceType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerAssetsServiceType;
 }
		export namespace QueueServiceTaskChain{
			export type  GetQueueMessInput=GetQueueMessInputQueueManagerQueueServiceTaskChainType;
 			export type  ListStringValue=ListStringValueQueueManagerQueueServiceTaskChainType;
 			export type  GetTasksListInput=GetTasksListInputQueueManagerQueueServiceTaskChainType;
 			export type  ListPointerTaskInfoValue=ListPointerTaskInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  ListPointerQueueInfoValue=ListPointerQueueInfoValueQueueManagerQueueServiceTaskChainType;
 			export type  QueueInfo=QueueInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListByteValue=ListByteValueQueueManagerQueueServiceTaskChainType;
 			export type  CancelTaskInput=CancelTaskInputQueueManagerQueueServiceTaskChainType;
 			export type  TaskStreamMessage=TaskStreamMessageQueueManagerQueueServiceTaskChainType;
 			export type  DirInfoStruct=DirInfoStructQueueManagerQueueServiceTaskChainType;
 			export type  DirInfo=DirInfoQueueManagerQueueServiceTaskChainType;
 			export type  TaskInfo=TaskInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListGetQueueMessRowValue=ListGetQueueMessRowValueQueueManagerQueueServiceTaskChainType;
 			export type  GetQueueMessRow=GetQueueMessRowQueueManagerQueueServiceTaskChainType;
 			export type  QueueServerInfo=QueueServerInfoQueueManagerQueueServiceTaskChainType;
 			export type  ListDirInfoStructValue=ListDirInfoStructValueQueueManagerQueueServiceTaskChainType;
 }
}
	export namespace TaskMonitor{
		export namespace Track{
			export type  MonitorInputMessage=MonitorInputMessageTaskMonitorTrackType;
 			export type  TaskMonitorQuery=TaskMonitorQueryTaskMonitorTrackType;
 			export type  MonitorMessage=MonitorMessageTaskMonitorTrackType;
 }
}
}
