import { ConfigKey, getEnv } from "@as-pl/env/src/getEnv";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { RpcTransport, ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import Cookies from "js-cookie";

interface ServiceConstructor {
    new (transport: RpcTransport): any;
}
export function configureService<T>(Service: ServiceConstructor): T {
    return new Service(getTransport(ConfigKey.NEXT_PUBLIC_BACKEND)) as T;
}

export function getTransport(key: ConfigKey): RpcTransport {
    const url = getEnv(key);
    if (!url) {
        throw new Error(`Missing env variable: ${key}`);
    }
    const transport = new GrpcWebFetchTransport({
        baseUrl: url,
        meta: {
            authorization: "Bearer " + Cookies.get("jwt"),
        },
    });

    return transport;
}

export class RecconectableStream<T extends object> {
    private connRetryTimeout = 1000;
    private connAttemptsCont = 1;
    private connRetryMax = 50;
    private messageSubscribers: ((message: T) => void)[] = [];
    public call: ServerStreamingCall<any, T> | null = null;

    constructor(factory: () => ServerStreamingCall<any, T>) {
        this.addFactory(factory);
    }

    addFactory(factory: () => ServerStreamingCall<any, T>) {
        const call = factory();
        this.call = call;
        call.responses.onMessage((message) => {
            this.messageSubscribers.forEach((subscriber) => subscriber(message));
        });

        call.responses.onError(async (error) => {
            let message = error.message || "Unknown error";
            if (message.toLowerCase() === "network error" || message.toLowerCase() === "failed to fetch") {
                console.log(
                    `Network error. Reconnecting... [ attemps left: ${this.connRetryMax - this.connAttemptsCont}, next in ...  ${(this.connRetryTimeout * this.connAttemptsCont) / 1000} s ]`,
                );
                await new Promise((resolve) => setTimeout(resolve, this.connRetryTimeout * this.connAttemptsCont));
                this.connAttemptsCont++;
                if (this.connAttemptsCont > this.connRetryMax) {
                    console.log("Max attempts reached, stopping...");
                    return;
                }

                this.addFactory(factory);

                return;
            }
        });
    }

    onMessage = (callback: (message: T) => void) => {
        this.messageSubscribers.push(callback);
    };
}
