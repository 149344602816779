import { MonitorMessage } from "./MonitorMessage";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";

export class Monitor {
    private connRetryTimeout = 1000;
    private connAttemptsCont = 1;
    private connRetryMax = 50;

    private logSubscribers: ((log: string) => void)[] = [];
    private infoSubscribers: ((log: string) => void)[] = [];
    private errorSubscribers: ((log: string) => void)[] = [];
    private activeSubscribers: ((active: boolean) => void)[] = [];
    private completedSubscribers: ((completed: boolean) => void)[] = [];
    private progressSubscribers: ((progress: [number, number]) => void)[] = [];
    private customMessageSubscribers: ((type: any, message: any) => void)[] = [];
    private active = false;

    addCall = (callFactory: () => ServerStreamingCall<any, MonitorMessage>) => {
        this.active = true;
        this.activeSubscribers.forEach((subscriber) => subscriber(this.active));
        const call = callFactory();
        call.responses.onMessage((message) => {
            if (message.Type == "INFO") {
                this.infoSubscribers.forEach((subscriber) => subscriber(message.Message));
            } else if (message.Type == "LOG") {
                this.logSubscribers.forEach((subscriber) => subscriber(message.Message));
            } else if (message.Type == "ERROR") {
                this.errorSubscribers.forEach((subscriber) => subscriber(message.Message));
            } else if (message.Type == "PROGRESS") {
                const tmp = message.Message.split("/");
                this.progressSubscribers.forEach((subscriber) => subscriber([parseInt(tmp[0] ?? "0"), parseInt(tmp[1] ?? "1")]));
            } else {
                this.customMessageSubscribers.forEach((subscriber) => subscriber(message.Type, message.Message));
            }
        });
        call.responses.onComplete(() => {
            this.completedSubscribers.forEach((subscriber) => subscriber(true));
        });
        call.responses.onError(async (error) => {
            let message = error.message || "Unknown error";
            if (message.toLowerCase() === "network error" || message.toLowerCase() === "failed to fetch") {
                console.log(
                    `Network error. Reconnecting... [ attemps left: ${this.connRetryMax - this.connAttemptsCont}, next in ...  ${(this.connRetryTimeout * this.connAttemptsCont) / 1000} s ]`,
                );
                await new Promise((resolve) => setTimeout(resolve, this.connRetryTimeout * this.connAttemptsCont));
                this.connAttemptsCont++;
                if (this.connAttemptsCont > this.connRetryMax) {
                    console.log("Max attempts reached, stopping...");
                    return;
                }

                this.infoSubscribers.forEach((subscriber) =>
                    subscriber(
                        `Network error. Reconnecting... [ attemps left: ${this.connRetryMax - this.connAttemptsCont}, next in ...  ${(this.connRetryTimeout * this.connAttemptsCont) / 1000} s ]`,
                    ),
                );

                this.addCall(callFactory);

                return;
            }

            if (message == "BodyStreamBuffer was aborted") {
                return;
            }

            message = message.replace("Failed to fetch", "Network problem");
            message = decodeURIComponent(message);
            this.errorSubscribers.forEach((subscriber) => subscriber(message));
            this.completedSubscribers.forEach((subscriber) => subscriber(true));
        });
    };

    public onInfo(callback: (log: string) => void) {
        this.infoSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.infoSubscribers = this.infoSubscribers.filter((item) => item !== callback);
            },
        };
    }

    public onLog(callback: (log: string) => void) {
        this.logSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.logSubscribers = this.logSubscribers.filter((item) => item !== callback);
            },
        };
    }

    public onError(callback: (log: string) => void) {
        this.errorSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.errorSubscribers = this.errorSubscribers.filter((item) => item !== callback);
            },
        };
    }

    public onProgressChange(callback: (progress: [number, number]) => void) {
        this.progressSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.progressSubscribers = this.progressSubscribers.filter((item) => item !== callback);
            },
        };
    }

    public onActiveChange(callback: (active: boolean) => void) {
        this.activeSubscribers.push(callback);
        callback(this.active);
        return {
            unsubscribe: () => {
                this.activeSubscribers = this.activeSubscribers.filter((item) => item !== callback);
            },
        };
    }

    public onCompletedChange(callback: (completed: boolean) => void) {
        this.completedSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.completedSubscribers = this.completedSubscribers.filter((item) => item !== callback);
            },
        };
    }

    public onCustomMessage(callback: (type: any, message: any) => void) {
        this.customMessageSubscribers.push(callback);
        return {
            unsubscribe: () => {
                this.customMessageSubscribers = this.customMessageSubscribers.filter((item) => item !== callback);
            },
        };
    }
}
