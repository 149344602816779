// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcComplains.proto" (package "grpcComplains", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ComplainsService } from "./grpcComplains";
import type { MonitorMessage } from "./grpcComplains";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { StringValue } from "./google/protobuf/wrappers";
import type { DataQuery } from "./grpcComplains";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcComplains.ComplainsService
 */
export interface IComplainsServiceClient {
    /**
     * @generated from protobuf rpc: Create(grpcComplains.DataQuery) returns (google.protobuf.StringValue);
     */
    create(input: DataQuery, options?: RpcOptions): UnaryCall<DataQuery, StringValue>;
    /**
     * @generated from protobuf rpc: AddComplainReportGenerationTask(grpcComplains.DataQuery) returns (stream grpcComplains.MonitorMessage);
     */
    addComplainReportGenerationTask(input: DataQuery, options?: RpcOptions): ServerStreamingCall<DataQuery, MonitorMessage>;
}
/**
 * @generated from protobuf service grpcComplains.ComplainsService
 */
export class ComplainsServiceClient implements IComplainsServiceClient, ServiceInfo {
    typeName = ComplainsService.typeName;
    methods = ComplainsService.methods;
    options = ComplainsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Create(grpcComplains.DataQuery) returns (google.protobuf.StringValue);
     */
    create(input: DataQuery, options?: RpcOptions): UnaryCall<DataQuery, StringValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<DataQuery, StringValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddComplainReportGenerationTask(grpcComplains.DataQuery) returns (stream grpcComplains.MonitorMessage);
     */
    addComplainReportGenerationTask(input: DataQuery, options?: RpcOptions): ServerStreamingCall<DataQuery, MonitorMessage> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DataQuery, MonitorMessage>("serverStreaming", this._transport, method, opt, input);
    }
}
