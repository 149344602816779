import React, { useEffect, useState } from "react";

import { client, queuesInfo } from "./state";

import { useAtom } from "jotai";

import styles from "./styles.module.sass";

import { IoIosPlay } from "@react-icons/all-files/io/IoIosPlay";
import { IoPauseOutline } from "@react-icons/all-files/io5/IoPauseOutline";

import { confirmDialog } from "serenity-controls";
import { Modal } from "serenity-controls";
import { RecconectableStream, Types } from "grpc-connect";
import { TaskDetails } from "./TaskDetails";
import { Trash2, X } from "lucide-react";

type TaskInfo = Types.QueueManager.QueueService.TaskInfo;

const QueuesServerMonitor = () => {
    const [previewState, setPreviewState] = useState<null | {
        queue: string;
        state: string;
        offset: number;
    }>(null);
    const [data, setData] = useAtom(queuesInfo);

    useEffect(() => {
        const abort = new AbortController();
        const stream = new RecconectableStream(() => client.getQueueServerInfoStream({ value: 1 }, { abort: abort.signal }));

        stream.onMessage((message) => {
            setData(message);
        });

        return () => {
            abort.abort();
        };
    }, []);

    return (
        <>
            <div className={styles.list}>
                <div className={styles.title}>
                    <span>Queues</span>
                </div>
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                <table>
                    <thead>
                        <tr>
                            <th>Queue</th>
                            <th>All</th>
                            <th>Oczekujące</th>
                            <th>Aktywne</th>
                            <th>Zakończone</th>
                            <th>Archiwum</th>
                            <th>Do powtórzenia</th>
                            <th>Przetworzone</th>
                            <th>Błędy</th>
                            <th style={{ width: 70, textAlign: "center" }}>Clear</th>
                            <th style={{ width: 70, textAlign: "center" }}>Delete</th>
                            <th style={{ width: 70, textAlign: "center" }}>Stop</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.Queues.map((queueInfo) => {
                            return (
                                <tr key={queueInfo.Queue}>
                                    <td>
                                        <div style={{ color: !queueInfo.Paused ? "green" : "red" }}>{queueInfo.Queue}</div>
                                    </td>
                                    <td>{queueInfo.Size}</td>
                                    <td>
                                        <a
                                            onClick={() => {
                                                setPreviewState({
                                                    queue: queueInfo.Queue,
                                                    state: "Pending",
                                                    offset: 0,
                                                });
                                            }}
                                        >
                                            {queueInfo.Pending}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            onClick={() => {
                                                setPreviewState({
                                                    queue: queueInfo.Queue,
                                                    state: "Active",
                                                    offset: 0,
                                                });
                                            }}
                                        >
                                            {queueInfo.Active}
                                        </a>
                                    </td>

                                    <td>
                                        <a
                                            onClick={() => {
                                                setPreviewState({
                                                    queue: queueInfo.Queue,
                                                    state: "Completed",
                                                    offset: 0,
                                                });
                                            }}
                                        >
                                            {queueInfo.Completed}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            onClick={() => {
                                                setPreviewState({
                                                    queue: queueInfo.Queue,
                                                    state: "Archived",
                                                    offset: 0,
                                                });
                                            }}
                                        >
                                            {queueInfo.Archived}
                                        </a>
                                    </td>

                                    <td>
                                        <a
                                            onClick={() => {
                                                setPreviewState({
                                                    queue: queueInfo.Queue,
                                                    state: "Retry",
                                                    offset: 0,
                                                });
                                            }}
                                        >
                                            {queueInfo.Retry}
                                        </a>
                                    </td>
                                    <td>{queueInfo.Processed}</td>
                                    <td>{queueInfo.Failed}</td>

                                    <td style={{ width: 70, textAlign: "center" }}>
                                        <a
                                            onClick={async () => {
                                                const ok = await confirmDialog("Czy na pewno chcesz wyczyścić kolejkę `" + queueInfo.Queue + "`?");
                                                if (!ok) return;

                                                await client.clearQueue({ value: queueInfo.Queue }).response;
                                            }}
                                        >
                                            <X />
                                        </a>
                                    </td>
                                    <td style={{ width: 70, textAlign: "center" }}>
                                        <a
                                            onClick={async () => {
                                                const ok = await confirmDialog("Czy na pewno chcesz usunąć kolejkę `" + queueInfo.Queue + "`?");
                                                if (!ok) return;
                                                await client.deleteQueue({ value: queueInfo.Queue }).response;
                                            }}
                                        >
                                            <Trash2 />
                                        </a>
                                    </td>
                                    <td style={{ width: 70, textAlign: "center" }}>
                                        <a
                                            onClick={async () => {
                                                await client.pausePlay({ value: queueInfo.Queue }).response;
                                            }}
                                        >
                                            {queueInfo.Paused ? <IoIosPlay /> : <IoPauseOutline />}
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {previewState !== null && (
                <Modal show={true} onHide={() => setPreviewState(null)}>
                    <PreviewView preview={previewState} />
                </Modal>
            )}
        </>
    );
};

const PreviewView = ({
    preview,
}: {
    preview: {
        queue: string;
        state: string;
        offset: number;
    };
}) => {
    const [tasks, setTasks] = useState<TaskInfo[] | null>(null);
    useEffect(() => {
        console.log(tasks);
        (async () => {
            const result = (
                await client.getTasksList({
                    Queue: preview.queue,
                    Type: [preview.state],
                    Offset: preview.offset,
                })
            ).response;
            setTasks(result.Value);
            console.log(result.Value);
        })();
    }, [preview]);

    const [details, setDetails] = React.useState<null | TaskInfo>(null);
    return (
        <div>
            <div className={styles.list}>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Payload</th>
                            <th>Result</th>
                            <th>Error</th>
                            <th>Completed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks?.map((task) => {
                            return (
                                <tr key={task.ID}>
                                    <td>
                                        <div
                                            style={{
                                                maxWidth: "100px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                            title={task.ID}
                                        >
                                            <a
                                                onClick={() => {
                                                    setDetails(task);
                                                }}
                                            >
                                                {task.ID}
                                            </a>
                                        </div>
                                    </td>
                                    <td>{task.Type}</td>
                                    <td>{atob(task.Payload)}</td>
                                    <td>{atob(task.Result)}</td>
                                    <td>{task.LastErr}</td>
                                    <td>{new Date(task.CompletedAt).getFullYear() > 1 ? new Date(task.CompletedAt).toLocaleString("pl-PL") : "---"}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {details && (
                    <Modal show={true} onHide={() => setDetails(null)}>
                        <TaskDetails task={details} />
                    </Modal>
                )}
            </div>
        </div>
    );
};

export { QueuesServerMonitor };
