// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcComplains.proto" (package "grpcComplains", syntax proto3)
// tslint:disable
import { StringValue } from "./google/protobuf/wrappers";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message grpcComplains.ListColumnValue
 */
export interface ListColumnValue {
    /**
     * @generated from protobuf field: repeated grpcComplains.Column Value = 1 [json_name = "Value"];
     */
    Value: Column[];
}
/**
 * @generated from protobuf message grpcComplains.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: string Field = 1 [json_name = "Field"];
     */
    Field: string;
    /**
     * @generated from protobuf field: string Value = 2 [json_name = "Value"];
     */
    Value: string;
    /**
     * @generated from protobuf field: string Condition = 3 [json_name = "Condition"];
     */
    Condition: string;
}
/**
 * @generated from protobuf message grpcComplains.ListOrderValue
 */
export interface ListOrderValue {
    /**
     * @generated from protobuf field: repeated grpcComplains.Order Value = 1 [json_name = "Value"];
     */
    Value: Order[];
}
/**
 * @generated from protobuf message grpcComplains.Order
 */
export interface Order {
    /**
     * @generated from protobuf field: string Caption = 1 [json_name = "Caption"];
     */
    Caption: string;
    /**
     * @generated from protobuf field: string Field = 2 [json_name = "Field"];
     */
    Field: string;
    /**
     * @generated from protobuf field: string Dir = 3 [json_name = "Dir"];
     */
    Dir: string;
}
/**
 * @generated from protobuf message grpcComplains.MapStringStringValue
 */
export interface MapStringStringValue {
    /**
     * @generated from protobuf field: map<string, string> Value = 1 [json_name = "Value"];
     */
    Value: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message grpcComplains.DataQuery
 */
export interface DataQuery {
    /**
     * @generated from protobuf field: repeated grpcComplains.Column Columns = 1 [json_name = "Columns"];
     */
    Columns: Column[];
    /**
     * @generated from protobuf field: repeated grpcComplains.Filter Filters = 2 [json_name = "Filters"];
     */
    Filters: Filter[];
    /**
     * @generated from protobuf field: repeated grpcComplains.Order Order = 3 [json_name = "Order"];
     */
    Order: Order[];
    /**
     * @generated from protobuf field: int64 OnPage = 4 [json_name = "OnPage"];
     */
    OnPage: number;
    /**
     * @generated from protobuf field: int64 CurrentPage = 5 [json_name = "CurrentPage"];
     */
    CurrentPage: number;
    /**
     * @generated from protobuf field: map<string, string> AdditionalConditions = 6 [json_name = "AdditionalConditions"];
     */
    AdditionalConditions: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message grpcComplains.MonitorMessage
 */
export interface MonitorMessage {
    /**
     * @generated from protobuf field: string Type = 1 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Job = 2 [json_name = "Job"];
     */
    Job: string;
    /**
     * @generated from protobuf field: string Message = 3 [json_name = "Message"];
     */
    Message: string;
}
/**
 * @generated from protobuf message grpcComplains.Column
 */
export interface Column {
    /**
     * @generated from protobuf field: string Field = 1 [json_name = "Field"];
     */
    Field: string;
    /**
     * @generated from protobuf field: string Caption = 2 [json_name = "Caption"];
     */
    Caption: string;
    /**
     * @generated from protobuf field: string Type = 3 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: string Default = 4 [json_name = "Default"];
     */
    Default: string;
}
/**
 * @generated from protobuf message grpcComplains.ListFilterValue
 */
export interface ListFilterValue {
    /**
     * @generated from protobuf field: repeated grpcComplains.Filter Value = 1 [json_name = "Value"];
     */
    Value: Filter[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListColumnValue$Type extends MessageType<ListColumnValue> {
    constructor() {
        super("grpcComplains.ListColumnValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Column }
        ]);
    }
    create(value?: PartialMessage<ListColumnValue>): ListColumnValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListColumnValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListColumnValue): ListColumnValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcComplains.Column Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Column.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListColumnValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcComplains.Column Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Column.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.ListColumnValue
 */
export const ListColumnValue = new ListColumnValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("grpcComplains.Filter", [
            { no: 1, name: "Field", kind: "scalar", localName: "Field", jsonName: "Field", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Condition", kind: "scalar", localName: "Condition", jsonName: "Condition", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Field = "";
        message.Value = "";
        message.Condition = "";
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Field = 1 [json_name = "Field"];*/ 1:
                    message.Field = reader.string();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.Value = reader.string();
                    break;
                case /* string Condition = 3 [json_name = "Condition"];*/ 3:
                    message.Condition = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Field = 1 [json_name = "Field"]; */
        if (message.Field !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Field);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.Value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Value);
        /* string Condition = 3 [json_name = "Condition"]; */
        if (message.Condition !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Condition);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOrderValue$Type extends MessageType<ListOrderValue> {
    constructor() {
        super("grpcComplains.ListOrderValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Order }
        ]);
    }
    create(value?: PartialMessage<ListOrderValue>): ListOrderValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListOrderValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListOrderValue): ListOrderValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcComplains.Order Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Order.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListOrderValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcComplains.Order Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Order.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.ListOrderValue
 */
export const ListOrderValue = new ListOrderValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Order$Type extends MessageType<Order> {
    constructor() {
        super("grpcComplains.Order", [
            { no: 1, name: "Caption", kind: "scalar", localName: "Caption", jsonName: "Caption", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Field", kind: "scalar", localName: "Field", jsonName: "Field", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Dir", kind: "scalar", localName: "Dir", jsonName: "Dir", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Order>): Order {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Caption = "";
        message.Field = "";
        message.Dir = "";
        if (value !== undefined)
            reflectionMergePartial<Order>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Order): Order {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Caption = 1 [json_name = "Caption"];*/ 1:
                    message.Caption = reader.string();
                    break;
                case /* string Field = 2 [json_name = "Field"];*/ 2:
                    message.Field = reader.string();
                    break;
                case /* string Dir = 3 [json_name = "Dir"];*/ 3:
                    message.Dir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Order, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Caption = 1 [json_name = "Caption"]; */
        if (message.Caption !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Caption);
        /* string Field = 2 [json_name = "Field"]; */
        if (message.Field !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Field);
        /* string Dir = 3 [json_name = "Dir"]; */
        if (message.Dir !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Dir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.Order
 */
export const Order = new Order$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapStringStringValue$Type extends MessageType<MapStringStringValue> {
    constructor() {
        super("grpcComplains.MapStringStringValue", [
            { no: 1, name: "Value", kind: "map", localName: "Value", jsonName: "Value", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<MapStringStringValue>): MapStringStringValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = {};
        if (value !== undefined)
            reflectionMergePartial<MapStringStringValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapStringStringValue): MapStringStringValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> Value = 1 [json_name = "Value"];*/ 1:
                    this.binaryReadMap1(message.Value, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: MapStringStringValue["Value"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof MapStringStringValue["Value"] | undefined, val: MapStringStringValue["Value"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field grpcComplains.MapStringStringValue.Value");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: MapStringStringValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> Value = 1 [json_name = "Value"]; */
        for (let k of globalThis.Object.keys(message.Value))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.Value[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.MapStringStringValue
 */
export const MapStringStringValue = new MapStringStringValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataQuery$Type extends MessageType<DataQuery> {
    constructor() {
        super("grpcComplains.DataQuery", [
            { no: 1, name: "Columns", kind: "message", localName: "Columns", jsonName: "Columns", repeat: 1 /*RepeatType.PACKED*/, T: () => Column },
            { no: 2, name: "Filters", kind: "message", localName: "Filters", jsonName: "Filters", repeat: 1 /*RepeatType.PACKED*/, T: () => Filter },
            { no: 3, name: "Order", kind: "message", localName: "Order", jsonName: "Order", repeat: 1 /*RepeatType.PACKED*/, T: () => Order },
            { no: 4, name: "OnPage", kind: "scalar", localName: "OnPage", jsonName: "OnPage", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "CurrentPage", kind: "scalar", localName: "CurrentPage", jsonName: "CurrentPage", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "AdditionalConditions", kind: "map", localName: "AdditionalConditions", jsonName: "AdditionalConditions", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<DataQuery>): DataQuery {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Columns = [];
        message.Filters = [];
        message.Order = [];
        message.OnPage = 0;
        message.CurrentPage = 0;
        message.AdditionalConditions = {};
        if (value !== undefined)
            reflectionMergePartial<DataQuery>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataQuery): DataQuery {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcComplains.Column Columns = 1 [json_name = "Columns"];*/ 1:
                    message.Columns.push(Column.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcComplains.Filter Filters = 2 [json_name = "Filters"];*/ 2:
                    message.Filters.push(Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated grpcComplains.Order Order = 3 [json_name = "Order"];*/ 3:
                    message.Order.push(Order.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 OnPage = 4 [json_name = "OnPage"];*/ 4:
                    message.OnPage = reader.int64().toNumber();
                    break;
                case /* int64 CurrentPage = 5 [json_name = "CurrentPage"];*/ 5:
                    message.CurrentPage = reader.int64().toNumber();
                    break;
                case /* map<string, string> AdditionalConditions = 6 [json_name = "AdditionalConditions"];*/ 6:
                    this.binaryReadMap6(message.AdditionalConditions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: DataQuery["AdditionalConditions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DataQuery["AdditionalConditions"] | undefined, val: DataQuery["AdditionalConditions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field grpcComplains.DataQuery.AdditionalConditions");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: DataQuery, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcComplains.Column Columns = 1 [json_name = "Columns"]; */
        for (let i = 0; i < message.Columns.length; i++)
            Column.internalBinaryWrite(message.Columns[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcComplains.Filter Filters = 2 [json_name = "Filters"]; */
        for (let i = 0; i < message.Filters.length; i++)
            Filter.internalBinaryWrite(message.Filters[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated grpcComplains.Order Order = 3 [json_name = "Order"]; */
        for (let i = 0; i < message.Order.length; i++)
            Order.internalBinaryWrite(message.Order[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 OnPage = 4 [json_name = "OnPage"]; */
        if (message.OnPage !== 0)
            writer.tag(4, WireType.Varint).int64(message.OnPage);
        /* int64 CurrentPage = 5 [json_name = "CurrentPage"]; */
        if (message.CurrentPage !== 0)
            writer.tag(5, WireType.Varint).int64(message.CurrentPage);
        /* map<string, string> AdditionalConditions = 6 [json_name = "AdditionalConditions"]; */
        for (let k of globalThis.Object.keys(message.AdditionalConditions))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.AdditionalConditions[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.DataQuery
 */
export const DataQuery = new DataQuery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MonitorMessage$Type extends MessageType<MonitorMessage> {
    constructor() {
        super("grpcComplains.MonitorMessage", [
            { no: 1, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Job", kind: "scalar", localName: "Job", jsonName: "Job", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Message", kind: "scalar", localName: "Message", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MonitorMessage>): MonitorMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Type = "";
        message.Job = "";
        message.Message = "";
        if (value !== undefined)
            reflectionMergePartial<MonitorMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MonitorMessage): MonitorMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Type = 1 [json_name = "Type"];*/ 1:
                    message.Type = reader.string();
                    break;
                case /* string Job = 2 [json_name = "Job"];*/ 2:
                    message.Job = reader.string();
                    break;
                case /* string Message = 3 [json_name = "Message"];*/ 3:
                    message.Message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MonitorMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Type = 1 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Type);
        /* string Job = 2 [json_name = "Job"]; */
        if (message.Job !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Job);
        /* string Message = 3 [json_name = "Message"]; */
        if (message.Message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.MonitorMessage
 */
export const MonitorMessage = new MonitorMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Column$Type extends MessageType<Column> {
    constructor() {
        super("grpcComplains.Column", [
            { no: 1, name: "Field", kind: "scalar", localName: "Field", jsonName: "Field", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Caption", kind: "scalar", localName: "Caption", jsonName: "Caption", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Default", kind: "scalar", localName: "Default", jsonName: "Default", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Column>): Column {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Field = "";
        message.Caption = "";
        message.Type = "";
        message.Default = "";
        if (value !== undefined)
            reflectionMergePartial<Column>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Column): Column {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Field = 1 [json_name = "Field"];*/ 1:
                    message.Field = reader.string();
                    break;
                case /* string Caption = 2 [json_name = "Caption"];*/ 2:
                    message.Caption = reader.string();
                    break;
                case /* string Type = 3 [json_name = "Type"];*/ 3:
                    message.Type = reader.string();
                    break;
                case /* string Default = 4 [json_name = "Default"];*/ 4:
                    message.Default = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Column, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Field = 1 [json_name = "Field"]; */
        if (message.Field !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Field);
        /* string Caption = 2 [json_name = "Caption"]; */
        if (message.Caption !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Caption);
        /* string Type = 3 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Type);
        /* string Default = 4 [json_name = "Default"]; */
        if (message.Default !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.Default);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.Column
 */
export const Column = new Column$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFilterValue$Type extends MessageType<ListFilterValue> {
    constructor() {
        super("grpcComplains.ListFilterValue", [
            { no: 1, name: "Value", kind: "message", localName: "Value", jsonName: "Value", repeat: 1 /*RepeatType.PACKED*/, T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<ListFilterValue>): ListFilterValue {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Value = [];
        if (value !== undefined)
            reflectionMergePartial<ListFilterValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFilterValue): ListFilterValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated grpcComplains.Filter Value = 1 [json_name = "Value"];*/ 1:
                    message.Value.push(Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFilterValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated grpcComplains.Filter Value = 1 [json_name = "Value"]; */
        for (let i = 0; i < message.Value.length; i++)
            Filter.internalBinaryWrite(message.Value[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpcComplains.ListFilterValue
 */
export const ListFilterValue = new ListFilterValue$Type();
/**
 * @generated ServiceType for protobuf service grpcComplains.ComplainsService
 */
export const ComplainsService = new ServiceType("grpcComplains.ComplainsService", [
    { name: "Create", options: {}, I: DataQuery, O: StringValue },
    { name: "AddComplainReportGenerationTask", serverStreaming: true, options: {}, I: DataQuery, O: MonitorMessage }
]);
