"use client";
import { FormValidationErrors, FormValidationResult } from "../form/FormValidationResult";
import { useEffect, useState } from "react";

import { ConfigKey, getEnv } from "@as-pl/env/getEnv";

import Cookies from "js-cookie";
import { ZodSchema } from "zod";

const allowSetParentDomain = true;

export interface UserData {
    id: number;
    login: string;
    groups: string[];
    isDev?: boolean;
}

export const API_COOKIE_NAME = "jwt";


export const authApiLoginFetcher = async (loginData: { login: string; password: string }): Promise<boolean | FormValidationErrors> => {
    const baseURL = getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) as string;
    const isDev = baseURL.includes("localhost") || baseURL.includes("as.dev");
    const rawResponse = await fetch(baseURL + "/auth/login", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: loginData }),
        credentials: "include",
    });

    const json: FormValidationResult<{
        redirectTo: string;
        jwt: string;
        user: UserData;
    }> = await rawResponse.json();

    if ("redirectTo" in json) {
        if (isDev) {
            Cookies.set(API_COOKIE_NAME, json.jwt, { expires: 3, domain: "localhost" });
            // this cookie is set by api server // change , disable set in server
            Cookies.set(API_COOKIE_NAME, json.jwt, { expires: 3, domain: "erp.as.dev" });

        } else {
            Cookies.set(API_COOKIE_NAME, json.jwt, { expires: 3 });
        }

        return true;
    } else {
        return json.validationErrors;
    }
};

export const isLogged = (): boolean => {
    return Cookies.get(API_COOKIE_NAME) ? true : false;
};

export const getUser = (): UserData | null => {
    const cookie = Cookies.get(API_COOKIE_NAME);

    if (cookie === undefined) {
        return null;
    }

    const encoded: string = cookie.split(".")[1] ?? "";
    const result = JSON.parse(atob(encoded));

    const val = result.data;

    return {
        login: val.userName,
        id: val.userId,
        groups: val.groups,
        isDev: val.groups.includes("Developers"),
    };
};

export const useUser = (): UserData | null => {
    const [user, setUser] = useState<UserData | null>(null);
    useEffect(() => {
        const cookie = Cookies.get("userData");
        const val = cookie ? JSON.parse(cookie) : null;
        if (val !== null) {
            val.isDev = val.groups.includes("Developers");
        }
        setUser(val);
    }, []);
    return user;
};

export const getJwt = (): string | undefined => {
    return Cookies.get("jwt");
};

export const isDev = (): boolean => {
    return false;
};

export const logout = async (): Promise<void> => {
    const baseURL = getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) as string;
    const isDev = baseURL.includes("localhost") || baseURL.includes("as.dev");
    if (isDev) {
        Cookies.remove(API_COOKIE_NAME, { domain: "localhost" });
        Cookies.remove(API_COOKIE_NAME, { domain: "erp.as.dev" });

    } else {
        console.log("remove cookie")
        Cookies.remove(API_COOKIE_NAME);
        Cookies.remove(API_COOKIE_NAME, { domain: "." + window.location.hostname });
    }


    await fetch(baseURL + "/auth/logout", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    if (isDev) {
        await fetch(getEnv(ConfigKey.NEXT_PUBLIC_FRONT_DOMAIN) + "/next/api/logout", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
    }
};

const notLoggedProcedure = () => {
    console.log("not logged");
};

const accessDenyProcedure = (url: string) => {
    const user = getUser();
    console.log(
        {
            url,
            login: user?.login,
            groups: user?.groups,
        },
        "not authorized ",
    );
};

export const authorizedFetch = async (url: string, options: RequestInit = {}, internalCounter = 0): Promise<Response> => {
    const baseURL = getEnv(ConfigKey.NEXT_PUBLIC_API_HOST) as string;
    if (url.substring(0, 1) === "/") {
        url = baseURL + url;
    }

    return new Promise((resolve, reject) => {
        if (internalCounter > 3) {
            reject("Authorization problems");
            return;
        }
        const modifiedOptions = {
            ...options,
            headers: {
                ...(options.headers ? options.headers : {}),
                Authorization: "Token " + Cookies.get("jwt"),
                //"X-Bearer-Token": "" + getCookie("jwt"),
            },
        };
        return fetch(url, modifiedOptions).then((response) => {
            if (response.status === 401) {
                notLoggedProcedure();
                return;
            } else if (response.status === 403) {
                accessDenyProcedure(url);
            } else {
                resolve(response);
            }
        });
    });
};

export const authorizedJSONRequest = async <T = object>(
    url: string,
    body: Record<string, object | string | number | null> | null = null,
    schema: ZodSchema<T> | null = null,
): Promise<{ data: T; response: Response }> => {
    const response = await authorizedFetch(url, {
        method: body !== null ? "POST" : "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: body !== null ? JSON.stringify(body) : null,
    });

    const responseBody = await response.text();

    try {
        const parsed = JSON.parse(responseBody);

        // if (schema !== null) {
        //   const validationResult = schema.safeParse(parsed);

        //   if (!validationResult.success) {
        //     throw new Error(
        //       "Response validation error " +
        //         validationResult.error +
        //         " form url: " +
        //         url
        //     );
        //   }
        // }

        return { data: parsed, response };
    } catch (ex) {
        throw new Error("Response parse exception " + ex + " form url: " + url);
    }
};

export const authorizedDownload = async (url: string) => {
    return authorizedFetch(url)
        .then((r) => r.blob())
        .then((blob) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            const blobUrl = window.URL.createObjectURL(blob);
            a.href = blobUrl;
            const tmp = url.split("/");
            //@ts-ignore link in ts dont have download attribute but its used in html
            a.download = tmp[tmp.length - 1];
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);
            }, 0);
        });
};
