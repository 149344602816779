import { useState } from "react";

import { IAfterGuiAttachedParams, IDoesFilterPassParams } from "ag-grid-enterprise";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Check, X } from "lucide-react";
import { useCallback, useEffect } from "react";

type IBooleanFilterProps = {
    target?: string;
    customTitle?: string;
} & CustomFilterProps;

export interface IBooleanFilterParams {
    yes?: { val: string | boolean | number; type: string };
    no?: { val: string | boolean | number; type: string };
}

export const BooleanFilter = ({ model, onModelChange, colDef, target, customTitle }: IBooleanFilterProps) => {
    const { filterParams } = colDef;

    const yes = filterParams?.yes || true;
    const no = filterParams?.no || false;

    const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
    const [unappliedModel, setUnappliedModel] = useState(model);

    const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
        return target !== undefined && params.data[target] === unappliedModel;
    }, []);

    const afterGuiAttached = useCallback(({ hidePopup }: IAfterGuiAttachedParams) => {
        setCloseFilter(() => hidePopup);
    }, []);

    // register filter handlers with the grid
    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });

    useEffect(() => {
        setUnappliedModel(model);
    }, [model]);

    const onChange = (value: { val: string | boolean | number; type: string }) => {
        setUnappliedModel(value.val);
        onModelChange({
            filterType: "text",
            type: value.type ?? "equals",
            filter: value.val,
            customField: target,
            customTitle: customTitle,
        });
        if (closeFilter) {
            closeFilter();
        }
    };

    return (
        <div>
            {customTitle && <div className="p-3 pl-5 font-bold">{customTitle}</div>}
            <div className="flex children:p-2 children:text-center children:grow">
                <div>
                    <div className="inline-block px-2 text-green-900 border-2 rounded-md cursor-pointer bg-neutral-200 hover:bg-neutral-300" onClick={() => onChange(yes)}>
                        <Check />
                    </div>
                </div>
                <div>
                    <div className="inline-block px-2 text-red-900 border-2 rounded-md cursor-pointer bg-neutral-200 hover:bg-neutral-300" onClick={() => onChange(no)}>
                        <X />
                    </div>
                </div>
            </div>
        </div>
    );
};
