import React from "react";
import { Monitor } from "./Monitor";
import { ProgressBar } from "./ProgressBar";
import { LoadingIndicatorFlat } from "serenity-controls";
import { Check } from "lucide-react";

export interface IMonitorData {
    progress: [number, number];
    logs: string[];
    info: string | null;
    errors: string[];
    active: boolean;
    completed: boolean;
}

export const MonitorWidget = ({
    title,
    observer,
    showIndicator,
    onActive,
    onCompleted,
    onCustomMessage,
    logsStyle,
    template,
}: {
    title: string;
    showIndicator?: boolean;
    observer: Monitor;
    onActive?: (active: boolean) => void;
    onCompleted?: (completed: boolean) => void;
    onCustomMessage?: (type: any, message: any) => void;
    logsStyle?: "expanded" | "collapsed";
    template?: (data: IMonitorData) => React.ReactNode;
}) => {
    const [logs, setLogs] = React.useState<string[]>([]);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [active, setActive] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<[number, number]>([0, 0]);
    const [info, setInfo] = React.useState<string | null>(null);
    const [logsOpened, setLogsOpened] = React.useState<boolean>(logsStyle === "expanded");

    logsStyle = logsStyle || "collapsed";

    React.useEffect(() => {
        const logSubscription = observer.onLog((log) => {
            setLogs((logs) => [...logs, log]);
        });

        const errorSubscription = observer.onError((log) => {
            setErrors((logs) => [...logs, log]);
        });

        const activeSubscription = observer.onActiveChange((active) => {
            setActive(active);
            onActive && onActive(active);
        });

        const completedSubscription = observer.onCompletedChange((completed) => {
            setCompleted(completed);
            onCompleted && onCompleted(completed);
        });

        const customMessageSubscription = observer.onCustomMessage((type, message) => {
            onCustomMessage && onCustomMessage(type, message);
        });
        const infoSubscribers = observer.onInfo((info) => {
            setInfo(info);
        });

        const progressSubscription = observer.onProgressChange((progress) => {
            setProgress(progress);
        });
        return () => {
            logSubscription.unsubscribe();
            errorSubscription.unsubscribe();
            activeSubscription.unsubscribe();
            completedSubscription.unsubscribe();
            completedSubscription.unsubscribe();
            customMessageSubscription.unsubscribe();
            progressSubscription.unsubscribe();
            infoSubscribers.unsubscribe();
        };
    }, [observer]);

    if (template) {
        return template({ progress, logs, info, errors, active, completed });
    }

    if (!active) {
        return null;
    }

    return (
        <div style={{ backgroundColor: "white", borderRadius: 10 }}>
            <div style={{ display: "flex", userSelect: "none" }}>
                {(completed || showIndicator) && (
                    <div style={{ paddingTop: 5 }}>
                        {completed ? (
                            <div style={{ color: "green", fontSize: 11 }}>
                                <Check />
                            </div>
                        ) : (
                            showIndicator && <LoadingIndicatorFlat />
                        )}
                    </div>
                )}
                <div style={{ padding: 5, flexGrow: 1, fontWeight: "bold", fontSize: 12 }}>{title}</div>
                {logs.length > 0 && (
                    <div
                        style={{
                            padding: 5,
                            fontSize: 12,
                        }}
                    >
                        <a onClick={() => setLogsOpened(!logsOpened)}>[ {logs.length} ]</a>
                    </div>
                )}
            </div>
            {info && <div style={{ padding: 3, paddingLeft: 0, fontSize: 12 }}>{info}</div>}
            {progress[1] > 0 && (
                <div>
                    <ProgressBar current={completed && errors.length === 0 ? progress[1] : progress[0]} all={progress[1]} />
                </div>
            )}
            {errors.length > 0 && (
                <pre
                    style={{
                        backgroundColor: "red",
                        color: "white",
                    }}
                >
                    {errors.join("\n")}
                </pre>
            )}
            {logs.length > 0 && (
                <div
                    style={{
                        maxHeight: 150,
                        overflowY: "auto",
                    }}
                >
                    {logsOpened && (
                        <pre
                            style={{
                                backgroundColor: "black",
                                color: "white",
                            }}
                        >
                            {logs.join("\n")}
                        </pre>
                    )}
                </div>
            )}
        </div>
    );
};
