// @generated by protobuf-ts 2.9.4 with parameter use_proto_field_name,long_type_number
// @generated from protobuf file "grpcProduct_tecodc.proto" (package "grpcProduct_tecodc", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProductEditService } from "./grpcProduct_tecodc";
import type { ListInsertManyAppsInputValue } from "./grpcProduct_tecodc";
import type { ListManySearchResultValue } from "./grpcProduct_tecodc";
import type { SearchForManyApplicationInput } from "./grpcProduct_tecodc";
import type { ListApplicationHistoryOutputValue } from "./grpcProduct_tecodc";
import type { ApplicationHistoryInput } from "./grpcProduct_tecodc";
import type { SearchData } from "./grpcProduct_tecodc";
import type { SearchDataInput } from "./grpcProduct_tecodc";
import type { GetAppListInput } from "./grpcProduct_tecodc";
import type { ApplicationResult } from "./grpcProduct_tecodc";
import type { GetAppGlobalInput } from "./grpcProduct_tecodc";
import type { ListGetLinkedCrossListRowValue } from "./grpcProduct_tecodc";
import type { GetLinkedCrossInput } from "./grpcProduct_tecodc";
import type { LinkAppsInput } from "./grpcProduct_tecodc";
import type { EngineResult } from "./grpcProduct_tecodc";
import type { GetEnginesInput } from "./grpcProduct_tecodc";
import type { ImportAppsInput } from "./grpcProduct_tecodc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { BoolValue } from "./google/protobuf/wrappers";
import type { MergeInput } from "./grpcProduct_tecodc";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpcProduct_tecodc.ProductEditService
 */
export interface IProductEditServiceClient {
    /**
     * @generated from protobuf rpc: MergeApplications(grpcProduct_tecodc.MergeInput) returns (google.protobuf.BoolValue);
     */
    mergeApplications(input: MergeInput, options?: RpcOptions): UnaryCall<MergeInput, BoolValue>;
    /**
     * @generated from protobuf rpc: ImportApplications(grpcProduct_tecodc.ImportAppsInput) returns (google.protobuf.BoolValue);
     */
    importApplications(input: ImportAppsInput, options?: RpcOptions): UnaryCall<ImportAppsInput, BoolValue>;
    /**
     * @generated from protobuf rpc: GetEngines(grpcProduct_tecodc.GetEnginesInput) returns (grpcProduct_tecodc.EngineResult);
     */
    getEngines(input: GetEnginesInput, options?: RpcOptions): UnaryCall<GetEnginesInput, EngineResult>;
    /**
     * @generated from protobuf rpc: LinkApllictaion(grpcProduct_tecodc.LinkAppsInput) returns (google.protobuf.BoolValue);
     */
    linkApllictaion(input: LinkAppsInput, options?: RpcOptions): UnaryCall<LinkAppsInput, BoolValue>;
    /**
     * @generated from protobuf rpc: GetLinkedCrossList(grpcProduct_tecodc.GetLinkedCrossInput) returns (grpcProduct_tecodc.ListGetLinkedCrossListRowValue);
     */
    getLinkedCrossList(input: GetLinkedCrossInput, options?: RpcOptions): UnaryCall<GetLinkedCrossInput, ListGetLinkedCrossListRowValue>;
    /**
     * @generated from protobuf rpc: GetApplicationGlobal(grpcProduct_tecodc.GetAppGlobalInput) returns (grpcProduct_tecodc.ApplicationResult);
     */
    getApplicationGlobal(input: GetAppGlobalInput, options?: RpcOptions): UnaryCall<GetAppGlobalInput, ApplicationResult>;
    /**
     * @generated from protobuf rpc: GetApplicationList(grpcProduct_tecodc.GetAppListInput) returns (grpcProduct_tecodc.ApplicationResult);
     */
    getApplicationList(input: GetAppListInput, options?: RpcOptions): UnaryCall<GetAppListInput, ApplicationResult>;
    /**
     * @generated from protobuf rpc: SearchForApplication(grpcProduct_tecodc.SearchDataInput) returns (grpcProduct_tecodc.ApplicationResult);
     */
    searchForApplication(input: SearchDataInput, options?: RpcOptions): UnaryCall<SearchDataInput, ApplicationResult>;
    /**
     * @generated from protobuf rpc: InsertNewApplication(grpcProduct_tecodc.SearchData) returns (google.protobuf.BoolValue);
     */
    insertNewApplication(input: SearchData, options?: RpcOptions): UnaryCall<SearchData, BoolValue>;
    /**
     * @generated from protobuf rpc: GetApplicationHistory(grpcProduct_tecodc.ApplicationHistoryInput) returns (grpcProduct_tecodc.ListApplicationHistoryOutputValue);
     */
    getApplicationHistory(input: ApplicationHistoryInput, options?: RpcOptions): UnaryCall<ApplicationHistoryInput, ListApplicationHistoryOutputValue>;
    /**
     * @generated from protobuf rpc: SearchForManyApplications(grpcProduct_tecodc.SearchForManyApplicationInput) returns (grpcProduct_tecodc.ListManySearchResultValue);
     */
    searchForManyApplications(input: SearchForManyApplicationInput, options?: RpcOptions): UnaryCall<SearchForManyApplicationInput, ListManySearchResultValue>;
    /**
     * @generated from protobuf rpc: InsertManyApps(grpcProduct_tecodc.ListInsertManyAppsInputValue) returns (google.protobuf.BoolValue);
     */
    insertManyApps(input: ListInsertManyAppsInputValue, options?: RpcOptions): UnaryCall<ListInsertManyAppsInputValue, BoolValue>;
}
/**
 * @generated from protobuf service grpcProduct_tecodc.ProductEditService
 */
export class ProductEditServiceClient implements IProductEditServiceClient, ServiceInfo {
    typeName = ProductEditService.typeName;
    methods = ProductEditService.methods;
    options = ProductEditService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: MergeApplications(grpcProduct_tecodc.MergeInput) returns (google.protobuf.BoolValue);
     */
    mergeApplications(input: MergeInput, options?: RpcOptions): UnaryCall<MergeInput, BoolValue> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<MergeInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ImportApplications(grpcProduct_tecodc.ImportAppsInput) returns (google.protobuf.BoolValue);
     */
    importApplications(input: ImportAppsInput, options?: RpcOptions): UnaryCall<ImportAppsInput, BoolValue> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ImportAppsInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEngines(grpcProduct_tecodc.GetEnginesInput) returns (grpcProduct_tecodc.EngineResult);
     */
    getEngines(input: GetEnginesInput, options?: RpcOptions): UnaryCall<GetEnginesInput, EngineResult> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEnginesInput, EngineResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: LinkApllictaion(grpcProduct_tecodc.LinkAppsInput) returns (google.protobuf.BoolValue);
     */
    linkApllictaion(input: LinkAppsInput, options?: RpcOptions): UnaryCall<LinkAppsInput, BoolValue> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<LinkAppsInput, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLinkedCrossList(grpcProduct_tecodc.GetLinkedCrossInput) returns (grpcProduct_tecodc.ListGetLinkedCrossListRowValue);
     */
    getLinkedCrossList(input: GetLinkedCrossInput, options?: RpcOptions): UnaryCall<GetLinkedCrossInput, ListGetLinkedCrossListRowValue> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLinkedCrossInput, ListGetLinkedCrossListRowValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetApplicationGlobal(grpcProduct_tecodc.GetAppGlobalInput) returns (grpcProduct_tecodc.ApplicationResult);
     */
    getApplicationGlobal(input: GetAppGlobalInput, options?: RpcOptions): UnaryCall<GetAppGlobalInput, ApplicationResult> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAppGlobalInput, ApplicationResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetApplicationList(grpcProduct_tecodc.GetAppListInput) returns (grpcProduct_tecodc.ApplicationResult);
     */
    getApplicationList(input: GetAppListInput, options?: RpcOptions): UnaryCall<GetAppListInput, ApplicationResult> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAppListInput, ApplicationResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchForApplication(grpcProduct_tecodc.SearchDataInput) returns (grpcProduct_tecodc.ApplicationResult);
     */
    searchForApplication(input: SearchDataInput, options?: RpcOptions): UnaryCall<SearchDataInput, ApplicationResult> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchDataInput, ApplicationResult>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: InsertNewApplication(grpcProduct_tecodc.SearchData) returns (google.protobuf.BoolValue);
     */
    insertNewApplication(input: SearchData, options?: RpcOptions): UnaryCall<SearchData, BoolValue> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchData, BoolValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetApplicationHistory(grpcProduct_tecodc.ApplicationHistoryInput) returns (grpcProduct_tecodc.ListApplicationHistoryOutputValue);
     */
    getApplicationHistory(input: ApplicationHistoryInput, options?: RpcOptions): UnaryCall<ApplicationHistoryInput, ListApplicationHistoryOutputValue> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApplicationHistoryInput, ListApplicationHistoryOutputValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchForManyApplications(grpcProduct_tecodc.SearchForManyApplicationInput) returns (grpcProduct_tecodc.ListManySearchResultValue);
     */
    searchForManyApplications(input: SearchForManyApplicationInput, options?: RpcOptions): UnaryCall<SearchForManyApplicationInput, ListManySearchResultValue> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchForManyApplicationInput, ListManySearchResultValue>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: InsertManyApps(grpcProduct_tecodc.ListInsertManyAppsInputValue) returns (google.protobuf.BoolValue);
     */
    insertManyApps(input: ListInsertManyAppsInputValue, options?: RpcOptions): UnaryCall<ListInsertManyAppsInputValue, BoolValue> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListInsertManyAppsInputValue, BoolValue>("unary", this._transport, method, opt, input);
    }
}
